import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import anime from 'animejs';

import { Link } from '../Link';

class Component extends React.Component {
  static displayName = 'Brand';

  static propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    energy: PropTypes.object.isRequired,
    audio: PropTypes.object.isRequired,
    sounds: PropTypes.object.isRequired,
    className: PropTypes.any,
    link: PropTypes.string,
    hover: PropTypes.bool,
    stableTime: PropTypes.bool,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onLinkStart: PropTypes.func,
    onLinkEnd: PropTypes.func
  };

  static defaultProps = {
    link: '/'
  };

  constructor () {
    super(...arguments);

    const { energy, stableTime } = this.props;

    if (!stableTime) {
      energy.updateDuration({ enter: 820 });
    }
  }

  componentWillUnmount () {
    const paths = this.svgElement.querySelectorAll('path');
    anime.remove(paths);
  }

  enter () {
    const { energy, sounds, stableTime, onEnter } = this.props;
    const paths = this.svgElement.querySelectorAll('path');

    anime.set(this.svgElement, { opacity: 1 });

    sounds.logo.play();

    anime({
      targets: paths,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      delay: (path, index) => stableTime ? 0 : index * energy.duration.stagger,
      duration: path => stableTime ? energy.duration.enter : path.getTotalLength(),
      complete: () => {
        onEnter && onEnter();
      }
    });
  }

  exit () {
    const { energy, sounds, onExit } = this.props;
    const paths = this.svgElement.querySelectorAll('path');

    sounds.fade.play();

    anime({
      targets: this.svgElement,
      easing: 'easeInCubic',
      duration: energy.duration.exit,
      opacity: 0
    });
    anime({
      targets: paths,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      direction: 'reverse',
      duration: energy.duration.exit,
      complete: () => {
        anime.set(this.svgElement, { opacity: 0 });
        onExit && onExit();
      }
    });
  }

  render () {
    const {
      theme,
      classes,
      energy,
      audio,
      sounds,
      className,
      link,
      hover,
      stableTime,
      onEnter,
      onExit,
      onLinkStart,
      onLinkEnd,
      ...etc
    } = this.props;

    return (
      <h1 className={cx(classes.root, hover && classes.hover, className)} {...etc}>
        <Link
          className={classes.link}
          href={link}
          title='U.A.G.I. logo'
          onLinkStart={onLinkStart}
          onLinkEnd={onLinkEnd}
        >
          <span className={classes.title}>U.A.G.I.</span>
          <svg
            ref={ref => (this.svgElement = ref)}
            className={classes.svg}
            viewBox='0 0 589.03 104.667'
            xmlns='http://www.w3.org/2000/svg'
            onMouseEnter={() => sounds.hover.play()}
          >
            <path d="M 0.001 62.182 L 0.001 1.765 L 32.488 1.765 L 32.488 61.741 A 25.891 25.891 0 0 0 33.077 67.423 Q 34.009 71.57 36.418 74.597 A 16.219 16.219 0 0 0 37.045 75.338 Q 41.445 80.235 49.683 80.404 A 28.949 28.949 0 0 0 50.275 80.41 A 23.347 23.347 0 0 0 55.646 79.829 Q 59.743 78.861 62.573 76.279 A 14.553 14.553 0 0 0 63.579 75.265 A 17.604 17.604 0 0 0 67.711 66.891 A 25.317 25.317 0 0 0 68.209 61.741 L 68.209 1.765 L 100.696 1.765 L 100.696 62.182 A 52.324 52.324 0 0 1 99.22 74.996 A 34.809 34.809 0 0 1 87.613 93.787 A 42.967 42.967 0 0 1 72.925 101.595 Q 66.6 103.597 58.951 104.294 A 94.156 94.156 0 0 1 50.422 104.665 Q 26.314 104.665 13.158 93.787 A 35.17 35.17 0 0 1 1.071 73.202 A 53.753 53.753 0 0 1 0.001 62.182 Z" id="0" vector-effect="non-scaling-stroke"/>
            <path d="M 146.707 102.901 L 115.543 102.901 L 115.543 73.648 L 146.707 73.648 L 146.707 102.901 Z" id="1" vector-effect="non-scaling-stroke"/>
            <path d="M 268.57 102.901 L 234.172 102.901 L 229.909 88.642 L 194.482 88.642 L 190.219 102.901 L 156.997 102.901 L 193.894 1.765 L 231.673 1.765 L 268.57 102.901 Z M 211.975 29.989 L 201.244 66.004 L 223.147 66.004 L 212.563 29.989 L 211.975 29.989 Z" id="2" vector-effect="non-scaling-stroke"/>
            <path d="M 310.024 102.901 L 278.86 102.901 L 278.86 73.648 L 310.024 73.648 L 310.024 102.901 Z" id="3" vector-effect="non-scaling-stroke"/>
            <path d="M 432.034 38.368 L 401.017 38.368 A 11.583 11.583 0 0 0 397.04 29.428 A 16.098 16.098 0 0 0 395.431 28.151 A 21.447 21.447 0 0 0 386.413 24.599 A 28.547 28.547 0 0 0 381.907 24.256 Q 370.441 24.256 364.561 30.356 A 19.872 19.872 0 0 0 359.807 38.824 Q 358.681 42.741 358.681 47.629 L 358.681 57.037 Q 358.681 65.511 362.064 71.068 A 18.576 18.576 0 0 0 364.561 74.309 A 18.683 18.683 0 0 0 372.663 79.157 Q 375.668 80.087 379.304 80.326 A 39.589 39.589 0 0 0 381.907 80.41 A 28.075 28.075 0 0 0 388.403 79.692 A 21.338 21.338 0 0 0 395.431 76.661 Q 401.017 72.913 401.017 67.033 L 378.085 67.033 L 378.085 46.453 L 432.034 46.453 L 432.034 102.901 L 415.276 102.901 L 412.042 92.905 Q 398.224 104.665 375.88 104.665 A 76.375 76.375 0 0 1 361.431 103.388 Q 353.318 101.823 347.027 98.339 A 37.458 37.458 0 0 1 338.101 91.508 A 41.411 41.411 0 0 1 328.82 75.745 Q 325.459 65.749 325.459 52.333 A 73.655 73.655 0 0 1 326.93 37.127 Q 330.05 22.346 339.792 13.304 A 46.651 46.651 0 0 1 358.443 2.924 Q 368.181 0.001 380.584 0.001 Q 395.137 0.001 406.75 4.411 A 46.628 46.628 0 0 1 417.456 10.072 A 38.559 38.559 0 0 1 425.199 17.42 A 31.322 31.322 0 0 1 431.773 33.687 A 40.977 40.977 0 0 1 432.034 38.368 Z" id="4" vector-effect="non-scaling-stroke"/>
            <path d="M 482.896 102.901 L 451.732 102.901 L 451.732 73.648 L 482.896 73.648 L 482.896 102.901 Z" id="5" vector-effect="non-scaling-stroke"/>
            <path d="M 536.551 102.901 L 504.064 102.901 L 504.064 1.765 L 536.551 1.765 L 536.551 102.901 Z" id="6" vector-effect="non-scaling-stroke"/>
            <path d="M 589.03 102.901 L 557.866 102.901 L 557.866 73.648 L 589.03 73.648 L 589.03 102.901 Z" id="7" vector-effect="non-scaling-stroke"/>
          </svg>
        </Link>
      </h1>
    );
  }
}

export { Component };
