// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-files-js": () => import("./../../../src/pages/files.js" /* webpackChunkName: "component---src-pages-files-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kickstarter-js": () => import("./../../../src/pages/kickstarter.js" /* webpackChunkName: "component---src-pages-kickstarter-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-see-video-js": () => import("./../../../src/pages/see-video.js" /* webpackChunkName: "component---src-pages-see-video-js" */),
  "component---src-pages-what-is-it-js": () => import("./../../../src/pages/what-is-it.js" /* webpackChunkName: "component---src-pages-what-is-it-js" */)
}

